/* eslint-disable no-unused-vars */
import { Button, Dialog, styled, Box } from '@mui/material';

export const StyledDialog = styled(Dialog)(({ theme }) => ({
  zIndex: 20000,
  background: 'rgba(8, 19, 44, 0.6)',
  backdropFilter: 'blur(2px)',
  textAlign: 'center',
  '& .MuiPaper-root': {
    minWidth: 320,
    maxWidth: 457,
    padding: '26px 26px 68px',
    background: theme.palette.primary.main,
    border: `1px solid ${theme.palette.blue.main}`,
    backdropFilter: 'blur(10px)',
    borderRadius: '20px',
  },
}));

export const OkButton = styled(Button)(({ theme }) => ({
  width: 150,
  backgroundColor: theme.palette.blue.main,
  border: 'none',
  color: theme.palette.white.main,
  textTransform: 'capitalize',
  borderRadius: '6px',
  '&:hover': {
    backgroundColor: theme.palette.blue.main,
    border: 'none',
  },
}));

export const CancelIconWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  width: '100%',
  justifyContent: 'flex-end',
  svg: {
    cursor: 'pointer',
  },
}));

export const DialogContent = styled(Box)({
  padding: '63px 32px 0px 32px',

  img: {
    maxWidth: 300,
    width: '100%',
  },
});

export const BackButton = styled(Button)(({ theme }) => ({
  color: theme.palette.blue.main,
  textAlign: 'center',
  marginTop: 2.6,
  textTransform: 'none',
  borderBottom: `1px solid ${theme.palette.blue.main}`,
  borderRadius: '0px',
  margin: 'auto',
  padding: '10.5px 0px',
}));
