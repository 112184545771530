import { SvgIcon, useTheme } from '@mui/material';

const CopyIcon = (props) => {
  const theme = useTheme();
  return (
    <SvgIcon viewBox="0 0 25 24" {...props}>
      <path
        d="M15.5 3H9.5C7.29086 3 5.5 4.79086 5.5 7V15"
        stroke={props.stroke || theme.palette.blue.main}
        strokeWidth="2"
      />
      <path
        d="M9.5 11.5C9.5 10.304 9.50149 9.49062 9.5712 8.87226C9.63864 8.27406 9.75916 7.98334 9.91329 7.78248C10.0197 7.64381 10.1438 7.51969 10.2825 7.41329C10.4833 7.25916 10.7741 7.13864 11.3723 7.0712C11.9906 7.00149 12.804 7 14 7C15.196 7 16.0094 7.00149 16.6277 7.0712C17.2259 7.13864 17.5167 7.25916 17.7175 7.41329C17.8562 7.51969 17.9803 7.64381 18.0867 7.78248C18.2408 7.98334 18.3614 8.27406 18.4288 8.87226C18.4985 9.49062 18.5 10.304 18.5 11.5V15.5C18.5 16.696 18.4985 17.5094 18.4288 18.1277C18.3614 18.7259 18.2408 19.0167 18.0867 19.2175C17.9803 19.3562 17.8562 19.4803 17.7175 19.5867C17.5167 19.7408 17.2259 19.8614 16.6277 19.9288C16.0094 19.9985 15.196 20 14 20C12.804 20 11.9906 19.9985 11.3723 19.9288C10.7741 19.8614 10.4833 19.7408 10.2825 19.5867C10.1438 19.4803 10.0197 19.3562 9.91329 19.2175C9.75916 19.0167 9.63864 18.7259 9.5712 18.1277C9.50149 17.5094 9.5 16.696 9.5 15.5V11.5Z"
        stroke={props.stroke || theme.palette.blue.main}
        strokeWidth="2"
      />
    </SvgIcon>
  );
};

export default CopyIcon;
