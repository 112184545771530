import { Button, Dialog, Divider, styled } from '@mui/material';

export const StyledDialog = styled(Dialog)(({ theme }) => ({
  zIndex: 20000,
  background: 'rgba(8, 19, 44, 0.6)',
  // position: 'relative',
  backdropFilter: 'blur(2px)',
  '& .MuiPaper-root': {
    minWidth: 320,
    maxWidth: 457,
    padding: '40px 24px',
    background: theme.palette.primary.main,
    border: `1px solid ${theme.palette.blue.main}`,
    backdropFilter: 'blur(10px)',
    borderRadius: '20px',
  },
}));

export const DisconnectButton = styled(Button)(({ theme }) => ({
  width: 194,
  backgroundColor: theme.palette.blue.main,
  border: 'none',
  color: theme.palette.white.main,
  textTransform: 'capitalize',
  borderRadius: '6px',
  fontWeight: 600,
  fontSize: '18px',
  height: '52px',
  lineHeight: '2.7',
  letterSpacing: '0px',
  marginLeft: '40px',
  [theme.breakpoints.down(664)]: {
    margin: '10px 0px 0px',
  },
  '&:hover': {
    backgroundColor: theme.palette.blue.main,
    border: 'none',
  },
}));

export const CancelButton = styled(Button)(({ theme }) => ({
  width: 150,
  backgroundColor: 'transform',
  border: '1px solid #2C4378',
  color: theme.palette.blue.main,
  textTransform: 'capitalize',
  borderRadius: '6px',
  '&:hover': {
    backgroundColor: 'transform',
    border: '1px solid #2C4378',
  },
}));

export const StyledDivider = styled(Divider)(({ theme }) => ({
  borderColor: theme.palette.mode === 'dark' ? theme.palette.blue.light : theme.palette.blue.light,
  margin: '8px -24px 20px -24px',
}));

export const StyledLink = styled('a')(({ theme }) => ({
  color: theme.palette.info.light,
  fontSize: 14,
  lineHeight: '24px',
  fontWeight: 400,
  textDecorationLine: 'underline',
  width: '140px',
  marginRight: '10px',
}));
