import { useState } from 'react';
import NodeInfoContext from '../Context/NodeInfoContext';

const CACHE_KEY = 'currentNode';

const NodeInfoProvider = ({ children }) => {
  const _node = localStorage.getItem(CACHE_KEY);

  const [nodeInfo, setNode] = useState(() => {
    return _node || '';
  });

  const setNodeInfo = (state) => {
    setNode(() => {
      localStorage.setItem(CACHE_KEY, state);
      return state;
    });
  };

  return (
    <NodeInfoContext.Provider value={{ nodeInfo, setNodeInfo }}>
      {children}
    </NodeInfoContext.Provider>
  );
};

export default NodeInfoProvider;
