import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  validatorStatus: '',
  bondAmount: '',
  commissionPercentage: '',
};

export const setUpValidator = createSlice({
  name: 'drawer',
  initialState,
  reducers: {
    setUpValidating: (state, action) => {
      state.validatorStatus = action.payload.isValidated;
      state.bondAmount = action.payload.bondAmount;
      state.commissionPercentage = action.payload.commissionPercentage;
    },
  },
});

export const { setUpValidating } = setUpValidator.actions;

export default setUpValidator.reducer;
