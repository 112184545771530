/*eslint-disable*/ import { Button, Typography } from '@mui/material';
import { useCallback, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { toast } from '../../components/Toast';
import { useNavigate } from 'react-router-dom';
import { attemptLogIn, logInSuccess, isNameExist } from '../../redux/reducer/auth';
import { useCreateNameMutation, useGetLoginDataQuery } from '../../redux/Api/LoginApi';
import Loader from '../../components/Loader/Loader';
import FundsModal from '../../components/FundsModal';
import ConnectionErrorModal from '../../components/ConnectionErrorModal';
import { setWalletAddress } from '../../redux/reducer/auth';

const ConnectWallet = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [balance, setBalance] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [connectionOpen, setConnectionOpen] = useState(false);
  const { address } = useSelector((state) => state.auth.user.address);
  const [createNameList, getError] = useCreateNameMutation();

  const submitHandler = async (event) => {
    event.preventDefault();
    if (!window.fire) {
      setConnectionOpen(true);
      return;
    }
    let windowAddress = '';
    await window?.fire
      ?.connect()
      .then((res) => {
        if (res?.nativeAddress) {
          dispatch(setWalletAddress(res?.nativeAddress));
        }
        windowAddress = res?.nativeAddress;
      })
      .catch((err) => {
        toast.error(err);
        return;
      });
    try {
      const response = fetch(
        `${process.env.REACT_APP_BASE_URL_SCAN_API}validator/${windowAddress}`,
        {
          method: 'GET',
          headers: {
            Accept: 'application/json',
          },
        },
      );
      const result = await (await response).json();
      // if (result.error) {
      //   toast.error(result?.message);
      //   return;
      // }
      // if (result.message.data?.name && result.message.data.wallet_address) {
      //   dispatch(isNameExist());
      //   const user = {
      //     name: result?.message?.data?.name,
      //     address: result?.message?.data?.wallet_address,
      //     balance,
      //   };
      //   dispatch(logInSuccess(user));
      //   navigate('/dashboard');
      //   return;
      // } else {
      //   navigate('account-setup');
      // }

      if (result?.data?.wallet_address) {
        const user = {
          name: '',
          address: result?.data?.wallet_address,
          balance: '',
        };
        dispatch(logInSuccess(user));
        navigate('/dashboard');
      } else {
        const data = {
          wallet_address: windowAddress,
          total_stake_amount: 0,
        };
        setIsLoading(true);
        const result = await createNameList(data);
        if (!result?.data?.error && !!result?.data?.data?.wallet_address) {
          const user = {
            name: '',
            address: result?.data?.data?.wallet_address,
            balance: '',
          };
          dispatch(logInSuccess(user));
          setIsLoading(false);
          navigate('/dashboard');
        } else {
          setIsLoading(false);
          if (windowAddress) {
            toast.error(result?.error?.data?.message);
          }
        }
      }
    } catch (err) {
      console.log(err);
      return err;
      // toast.error('Something went wrong');
    }
  };

  const connectionClose = useCallback(() => {
    setConnectionOpen(false);
  }, []);

  return (
    <>
      <Button
        variant="contained"
        sx={{
          margin: '52px 21px 0px 44px',
          padding: '16px 17px !important',
          borderRadius: '8px !important',
          height: '48px !important',
        }}
        onClick={submitHandler}
      >
        Connect wallet
      </Button>
      {isLoading ? <Loader /> : null}
      {/* {isOpen && (
        <FundsModal isOpen={isOpen} handleClose={handleClose} notConnected={notConnected} />
      )} */}
      {connectionOpen && (
        <ConnectionErrorModal connectionOpen={connectionOpen} connectionClose={connectionClose} />
      )}
    </>
  );
};

export default ConnectWallet;