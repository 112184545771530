import { styled } from '@mui/material';

export const Wrapper = styled('div')(({ theme }) => ({
  background: theme.palette.secondary.darker,
  display: 'flex',
  borderRadius: '8px',
  justifyContent: 'center',
  padding: '6px 34px',
  alignItems: 'center',

  '.MuiSvgIcon-root': {
    fill: 'transparent',
  },
}));
