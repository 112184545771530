/*eslint-disable*/
import { DialogActions, DialogContent, Typography, useTheme } from '@mui/material';
import { useState } from 'react';
import { StyledDialog, StyledTextFiled, OkButton, CancelButton } from './styles';
import { toast } from '../Toast';

const NodeInfoModal = ({ isOpen, handleClose, close, showClose }) => {
  const [nodeInfo, setNodeInfo] = useState('');
  // const [nodeCheck, setNodeCheck] = useState();
  const [nodeErr, setNodeErr] = useState('');

  // const handleChange = (e) => {
  //   const nodeInfoStr = e.target.value;
  //   const ipPortRegex =
  //     /^(ws|wss):\/\/((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?):(\d{1,5})$/;
  //   const urlRegex = /^(ws|wss):\/\/[\w-]+(\.[\w-]+)+(:\d{1,5})?$/;
  //   const urlOrIpRegex = /^(wss?:\/\/)?((([0-9]{1,3}\.){3}[0-9]{1,3})|([a-zA-Z0-9.-]+))(:[0-9]+)?$/;

  //   setNodeInfo(nodeInfoStr);

  //   // Check if the input is a valid URL or IP with port
  //   if (!nodeInfoStr.includes('9944') || !nodeInfoStr.includes('44')) {
  //     // setNodeCheck(false);
  //     setNodeErr('Enter valid node url!');
  //   } else if (
  //     ipPortRegex.test(nodeInfoStr) ||
  //     urlRegex.test(nodeInfoStr) ||
  //     urlOrIpRegex.test(nodeInfoStr)
  //   ) {
  //     setNodeErr('');
  //   } else if (nodeInfoStr == '[object Object]') {
  //     setNodeErr('Enter valid node url!');
  //   } else {
  //     setNodeErr('Enter valid node url!');
  //   }
  // };

  /**************************************************** */
  // const handleChange = (e) => {
  //   const nodeInfoStr = e.target.value;

  //   // Regex patterns for IP and URL validation
  //   const ipPortRegex =
  //     /^(ws|wss):\/\/((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?):(\d{1,5})$/;
  //   const urlRegex = /^(ws|wss|https?):\/\/[\w-]+(\.[\w-]+)+(:\d{1,5})?$/;
  //   const urlOrIpRegex =
  //     /^(wss?:\/\/|https?:\/\/)?((([0-9]{1,3}\.){3}[0-9]{1,3})|([a-zA-Z0-9.-]+))(:[0-9]+)?$/;

  //   // Set the node information
  //   setNodeInfo(nodeInfoStr);

  //   // Check for specific substrings '99' and '44' in IP address
  //   const contains99and44 = nodeInfoStr.includes('99') && nodeInfoStr.includes('44');

  //   // Check if the input is a valid URL or IP with port
  //   if (
  //     (ipPortRegex.test(nodeInfoStr) ||
  //       urlRegex.test(nodeInfoStr) ||
  //       urlOrIpRegex.test(nodeInfoStr)) &&
  //     (nodeInfoStr.includes('wss') || contains99and44)
  //   ) {
  //     setNodeErr('');
  //   } else {
  //     setNodeErr('Enter valid node URL!');
  //   }
  // };

  const handleChange = (e) => {
    const nodeInfoStr = e.target.value;

    // Regex patterns for IP and URL validation
    const ipPortRegex =
      /^(ws|wss):\/\/((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?):(\d{1,5})$/;
    const urlRegex = /^(ws|wss|https?):\/\/[\w.-]+(\.[\w.-]+)+(:\d{1,5})?(\/[^\s]*)?$/;
    const urlOrIpRegex =
      /^(wss?:\/\/|https?:\/\/)?((([0-9]{1,3}\.){3}[0-9]{1,3})|([\w.-]+))(:[0-9]+)?(\/[^\s]*)?$/;

    // Set the node information
    setNodeInfo(nodeInfoStr);

    // Check for specific substrings '99' and '44' in IP address or path
    const contains99and44 = nodeInfoStr.includes('99') && nodeInfoStr.includes('44');


    // Check if the input is a valid URL or IP with port and optionally path
    if (
      (ipPortRegex.test(nodeInfoStr) ||
        urlRegex.test(nodeInfoStr) ||
        urlOrIpRegex.test(nodeInfoStr)) &&
      (nodeInfoStr.includes('wss') || contains99and44)
    ) {
      setNodeErr('');
    } else {
      setNodeErr('Enter a valid node URL!');
    }
  };

  const theme = useTheme();

  return (
    <StyledDialog
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="logout-dialog-title"
      aria-describedby="logout-dialog-description"
    >
      <Typography
        variant="h1"
        color={theme.palette.text.tertiary}
        textAlign="center"
        sx={{ lineHeight: 1.4 }}
      >
        Please put
        <br />
        your node information
      </Typography>
      <DialogContent sx={{ padding: '24px 0' }}>
        <StyledTextFiled
          id="node-info"
          label="Node Information"
          value={nodeInfo}
          onChange={handleChange}
          autoComplete="off"
        />
      </DialogContent>
      <p style={{ color: 'red', fontSize: '15px', marginTop: '-20px', marginLeft: '10px' }}>
        {nodeErr}
      </p>
      <DialogActions sx={{ justifyContent: 'center' }}>
        <OkButton
          variant="contained"
          onClick={() => handleClose(nodeInfo)}
          disabled={nodeErr ? true : false}
        >
          Submit
        </OkButton>
        {showClose && (
          <CancelButton variant="contained" onClick={() => close()}>
            Cancel
          </CancelButton>
        )}
      </DialogActions>
    </StyledDialog>
  );
};

export default NodeInfoModal;
