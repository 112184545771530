/* eslint-disable */
import { Box, Button, IconButton, Typography, useMediaQuery, useTheme } from '@mui/material';
import React, { useCallback, useRef, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import LinkDropdown from './AccountDropdown';
import useOnClickOutside from '../../hooks/useOnClickOutside';
import { notLoggedInRoutes, loggedInRoutes } from '../../utils/navItems';
import getData from '../../utils/getData';
import { Wrapper, WalletDetails, NavItem, SubLink, AddressWrapper } from './styles';
import Address from '../Common/Address';
import ConnectionErrorModal from '../ConnectionErrorModal';
import CancelIcon from '../../assets/Icons/CancelIcon';
import WalletIcon from '../../assets/Icons/WalletIcon';
import DropdownIcon from '../../assets/Icons/DropdownIcon';
import Logo from '../../assets/Icons/LogoIcon';
import RefreshIcon from '../../assets/Icons/RefreshIcon';
import { getAmount } from '../../services/getAmount';
import { bondAmount } from '../../services/bondAmount';
import axios from 'axios';
import { logout, getUsersBalance } from '../../redux/reducer/auth';
import Loader from '../Loader/Loader';
import { getApiData } from '../../utils/webClientsContext';
// import Swal from 'sweetalert2';
import { toast } from '../Toast';
import { getBalance } from '../../services/getBalance';
import { formatNumbers } from '../../utils/formatNumbers';
import { keyframes } from '@emotion/react';
import FlexBox from '../../components/Common/FlexBox';
import LogoutIcon from '../../assets/Icons/LogoutIcon';
import { isStr, jObj, jStr, rEqual } from '../../utils/utils';
import DisconnectModal from '../DisconnectModal';
import NodeInfoModal from '../NodeInfoModal';
import useNodeInfoContext from '../../hooks/useNodeInfoContext';
import useColorMode from '../../hooks/useColorMode';
import ThemeSwitcher from '../ThemeSwitcher';
import ConnectWallet from '../../pages/Login/connectWallet';
import ArrowRightIcon from '../../assets/Icons/ArrowRight';

const wave = keyframes`
  from  {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const Sidebar = ({ isMobileSidebarOpen, setIsMobileSidebarOpen }) => {
  const { nodeInfo, setNodeInfo } = useNodeInfoContext();
  const ref = useRef();
  const { api, setNodeIp, nodeIp, setIsNodeExist, isValidatorNode } = getApiData();
  const location = useLocation();
  const theme = useTheme();
  // const [nodeUrl, setNodeUrl] = useState(process.env.REACT_APP_SOCKET_HOST);
  // const [tempNodeUrl, setTempNodeUrl] = useState(process.env.REACT_APP_SOCKET_HOST);
  const [connectedWithNode, setIsConnectedWithNode] = useState(false);
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const { isLoggedIn, user } = useSelector((state) => state.auth);
  const { address } = useSelector((state) => state.auth.user);
  const { showUserBalance } = useSelector((state) => state.auth);
  const { balance } = useSelector((state) => state.auth);
  const [isLoading, setIsLoading] = useState(false);
  const [spin, setSpin] = useState(false);
  const [spinNode, setSpinNode] = useState(false);
  const [isLogout, setIsLogout] = useState(false);
  const [showClose, setShowClose] = useState(false);
  const [isOpenedNodeInfo, setIsOpenedNodeInfo] = useState(false);
  const [userName, setUserName] = useState('');
  const { toggleColorMode } = useColorMode();
  const [connectionOpen, setConnectionOpen] = useState(false);
  // const { userName } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useOnClickOutside(ref, () => setIsMobileSidebarOpen(false));

  const { pathname } = location;
  const routes = isLoggedIn ? loggedInRoutes : notLoggedInRoutes;

  const closeSidebar = useCallback(() => {
    setIsMobileSidebarOpen(false);
  }, [setIsMobileSidebarOpen]);


  useEffect(() => {
    setIsLoading(true);
    if (api) {
      setIsLoading(false);
      if (rEqual(api, 'error')) {
        setIsConnectedWithNode(!1);
        toast.error('Not Connected With node');
        return;
      } if (isValidatorNode == true) {
        setIsConnectedWithNode(!1);
        toast.error('This is not validator node');
        return;
      } else {
        // toast.success('Connected with Node');
        setIsNodeExist(true);
        setIsConnectedWithNode(true);
      }
    }
  }, [api, nodeIp, connectedWithNode]);

  useEffect(() => {
    sessionStorage.setItem('isConnected', connectedWithNode);
  }, [connectedWithNode]);

  useEffect(() => {
    if (api !== null) {
      getBalances();
    }
  }, [address, api]);

  useEffect(() => {
    setIsLoading(true);
    if (nodeInfo) {
      setNodeIp(nodeInfo);
      setIsLoading(false);
      setIsOpenedNodeInfo(false);
    } else {
      setIsOpenedNodeInfo(true);
    }
  }, [nodeInfo, setNodeIp]);

  const getBalances = async () => {
    const userAllBalance = await getBalance(address, api);
    dispatch(getUsersBalance(userAllBalance));
  };

  const handleLogout = () => {
    setIsLogout(true);
  };

  const handleClose = useCallback(() => {
    setIsLogout(false);
  }, []);

  const close = () => {
    setIsOpenedNodeInfo(false);
  };

  const handleLogout1 = () => {
    dispatch(logout());
    setIsNodeExist(false);
    navigate('/login');
    setNodeInfo('');
    const currentTheme = localStorage.getItem('light');
    localStorage.clear();
    localStorage.setItem('light', currentTheme);
    sessionStorage.clear();
    setIsLogout(false);
  };

  const logoutHandler = useCallback(() => {
    try {
      if (window?.fire) {
        if (window.fire.connected === false) {
          handleLogout1();
        } else {
          window?.fire
            ?.disconnect()
            .then((res) => {
              if (res) {
                handleLogout1();
                window?.fire?.removeAllListeners();
              }
            })
            .catch((e) => {
              console.log('error : ', e);
              handleLogout1();
              window?.fire?.removeAllListeners();
            });
        }
      } else {
        handleLogout1();
      }
    } catch (error) {
      console.log(error, 'Error while disconnect');
      handleLogout1();
      window?.fire?.removeAllListeners();
    }
  }, [dispatch, navigate]);

  const buttonMove = async () => {
    setSpin(true);
    const move = await new Promise((resolve) => {
      setTimeout(async () => {
        resolve();
      }, 2000);
    });
    setSpin(false);
  };

  const balanceRefresh = () => {
    getBalances();
    buttonMove();
  };

  const connectionClose = () => {
    setConnectionOpen(false);
  };

  // const handleNodeInfo = (_nodeInfo) => {
  //   if (!_nodeInfo) {
  //     toast.error('Enter Node Url');
  //     return;
  //   }
  //   if (
  //     _nodeInfo?.includes('ws://') ||
  //     _nodeInfo?.includes('https://') ||
  //     _nodeInfo?.includes('http://') ||
  //     _nodeInfo?.includes('wss://')
  //   ) {
  //     setNodeInfo(_nodeInfo);
  //     // toast.success('Connected with Node');
  //     window.location.reload();
  //   } else {
  //     toast.error('Invalid Node URL');
  //   }
  // };

  const handleNodeInfo = (_nodeInfo) => {
    if (!_nodeInfo) {
      toast.error('Enter Node Url');
      return;
    }

    // Ensure _nodeInfo is a string
    const nodeInfoStr = String(_nodeInfo);

    if (
      nodeInfoStr.includes('ws://') ||
      nodeInfoStr.includes('https://') ||
      nodeInfoStr.includes('http://') ||
      nodeInfoStr.includes('wss://')
    ) {
      setNodeInfo(nodeInfoStr);
      // toast.success('Connected with Node');
      window.location.reload();
    } else if (nodeInfoStr == '[object Object]') {
      toast.error('Enter valid node URL');
    } else {
      toast.error('Invalid Node URL');
    }
  };

  Number.prototype.noExponents = function () {
    var data = String(this).split(/[eE]/);
    if (data.length == 1) return data[0];

    var z = '',
      sign = this < 0 ? '-' : '',
      str = data[0].replace('.', ''),
      mag = Number(data[1]) + 1;

    if (mag < 0) {
      z = sign + '0.';
      while (mag++) z += '0';
      return z + str.replace(/^\-/, '');
    }
    mag -= str.length;
    while (mag--) z += '0';
    return str + z;
  };

  return (
    <Wrapper isopen={`${isMobileSidebarOpen}`} ref={ref}>
      <div>
        {isMobile && (
          <Box ml="10px">
            <IconButton onClick={closeSidebar}>
              <CancelIcon
                sx={{
                  color: (theme) => theme.palette.text.primary,
                  height: '40px',
                  width: '40px',
                }}
              />
            </IconButton>
          </Box>
        )}
        <Box display="flex" alignItems="center" paddingLeft="40px" paddingRight="21px">
          <Logo
            sx={{
              flexShrink: '0',
              fontSize: '55px',
              path: {
                fill: (theme) => theme.palette.text.tertiary,
              },
            }}
          />
          <Box ml="8px" color={theme.palette.text.tertiary}>
            <Typography variant="h3">5ireChain</Typography>
            <Typography variant="h6">
              Validator <br />
              Application
            </Typography>
          </Box>
        </Box>
        {isLoggedIn && (
          <WalletDetails>
            {/* <Box display="flex" mb="12px" alignItems="center">
              <WalletIcon
                sx={{
                  fontSize: '22px',
                  path: {
                    stroke: theme.palette.blue.main,
                  },
                }}
              />
              <Typography variant="h3" color={theme.palette.blue.main} fontWeight="600" ml="20px">
                {userName ? userName : '-'}
              </Typography>
            </Box> */}
            <AddressWrapper onClick={handleLogout}>
              <Typography mr="8px" variant={'body2'} color="text.quartenery">
                {getData(user.address)}
              </Typography>
              <ArrowRightIcon
                sx={{
                  fontSize: '24px',
                }}
              />
            </AddressWrapper>
            <Typography
              color="text.secondary"
              variant="body2"
              mt="12px"
              sx={{ display: 'flex', alignItems: 'center' }}
            >
              Total Balance:{' '}
              <RefreshIcon
                sx={{
                  fontSize: '20px',
                  marginLeft: '11px',
                  cursor: 'pointer',
                  animation: spin ? `${wave} 1s 1` : 'none',
                }}
                onClick={balanceRefresh}
              />
            </Typography>
            <Typography mt="4px" fontWeight="500">
              {balance?.total && balance?.total !== NaN ? formatNumbers(balance?.total, 4) : 0} 5IRE
            </Typography>
            <Typography
              color="text.secondary"
              variant="body2"
              mt="12px"
              sx={{ display: 'flex', alignItems: 'center' }}
            >
              Available Balance:{' '}
              <RefreshIcon
                sx={{
                  fontSize: '20px',
                  marginLeft: '11px',
                  cursor: 'pointer',
                  animation: spin ? `${wave} 1s 1` : 'none',
                }}
                onClick={balanceRefresh}
              />
            </Typography>
            <Typography mt="4px" fontWeight="500">
              {balance?.availableBalance && balance?.availableBalance !== NaN
                ? formatNumbers(balance?.availableBalance, 4)
                : '0'}{' '}
              5IRE
            </Typography>
          </WalletDetails>
        )}
        {!isLoggedIn ? <ConnectWallet /> : null}
        <Box component="nav" mt="20px">
          {routes.map((item) => (
            <React.Fragment key={item.label}>
              {item.hasSubLinks ? (
                <LinkDropdown
                  trigger={
                    <NavItem key={item.label} active={`${pathname.includes(item.path)}`}>
                      <item.Icon />
                      <Typography variant="body1" ml="24px" mr="1px">
                        {item.label}
                      </Typography>
                      <DropdownIcon
                        sx={{
                          flexShrink: 0,
                        }}
                      />
                    </NavItem>
                  }
                >
                  {item.subLinks.map((item) => (
                    <div key={item.label}>
                      <SubLink to={item.path} active={`${pathname.includes(item.path)}`}>
                        {item.label}
                      </SubLink>
                    </div>
                  ))}
                </LinkDropdown>
              ) : (
                <NavItem to={item.path} active={`${pathname.includes(item.path)}`}>
                  <item.Icon />
                  <Typography variant="body1" ml="24px" fontWeight="400">
                    {item.label}
                  </Typography>
                </NavItem>
              )}
            </React.Fragment>
          ))}
        </Box>
      </div>

      {/* <Box
        component="nav"
        sx={{ padding: '10.5px 21px 10.5px 44px', cursor: 'pointer' }}
        onClick={handleLogout}
      >
        {isLoggedIn ? (
          <FlexBox>
            <LogoutIcon />
            <p style={{ margin: '0 0 0 24px' }}>Logout</p>
          </FlexBox>
        ) : (
          ''
        )}
      </Box> */}
      <Box pl="40px" pt={10}>
        <ThemeSwitcher />
        <Box display="flex" alignItems="center">
          <Typography fontSize="20px" lineHeight="30px" fontWeight="700" marginRight="26.74px">
            Node
            <br />
            Information
          </Typography>
        </Box>
        <Typography mt="0px" variant="body2" style={{ wordWrap: 'break-word', paddingRight: '10px' }}
          color={theme.palette.gray.dark}>
          {/* 5ire:
          <input
            value={tempNodeUrl}
            onChange={(event) => {
              setTempNodeUrl(event.target.value);
            }}
            style={{
              marginLeft: '10px',
              padding: '6px',
              background: 'transparent',
              color: '#fff',
              boxShadow: 'none',
              border: '1px solid white',
              borderRadius: '4px',
            }}
          />
          <Button
            variant="contained"
            onClick={(event) => {
              event.preventDefault();
              if (!tempNodeUrl) {
                toast.error('Enter Node Url');
                return;
              }
              if (
                tempNodeUrl.includes('ws://') ||
                tempNodeUrl.includes('https://') ||
                tempNodeUrl.includes('http://') ||
                tempNodeUrl.includes('wss://')
              ) {
                setNodeUrl(tempNodeUrl);
                setNodeIp(tempNodeUrl);
                sessionStorage.setItem('nodeIp', tempNodeUrl);
                window.location.reload();
              } else {
                toast.error('Invalid Node URL');
              }
            }}
            style={{ padding: '10px 18px', marginTop: '11px' }}
          >
            Submit
          </Button> */}
          <br />
          {connectedWithNode ? nodeInfo : 'Not Connected With Node'}
        </Typography>
      </Box>
      {connectedWithNode && !isLoggedIn && (
        <Button
          variant="contained"
          sx={{
            margin: '52px 21px 0px 44px',
            padding: '16px 17px !important',
            borderRadius: '8px !important',
            height: '48px !important',
          }}
          onClick={() => {
            setIsOpenedNodeInfo(true);
            setShowClose(true);
          }}
        >
          Change Node Ip
        </Button>
      )}

      {isLoading ? <Loader /> : null}
      <DisconnectModal isOpen={isLogout} logoutHandler={logoutHandler} handleClose={handleClose} />
      {!isLoggedIn && (
        <NodeInfoModal
          isOpen={isOpenedNodeInfo || (!connectedWithNode && !isLoading)}
          handleClose={handleNodeInfo}
          close={close}
          showClose={showClose}
        />
      )}
      {connectionOpen && (
        <ConnectionErrorModal connectionOpen={connectionOpen} connectionClose={connectionClose} />
      )}
    </Wrapper>
  );
};

export default Sidebar;