import { Box, Container as BaseContainer, styled } from '@mui/material';

export const Wrapper = styled(Box)(({ theme }) => ({
  minHeight: '100vh',
  background: theme.palette.primary.dark,
  position: 'relative',
  display: 'flex',

  '.MuiTableCell-root': {
    borderBottom: `1px solid ${theme.palette.divider}`,
    paddingTop: '20px',
    paddingBottom: '20px',
  },
  '.MuiTableHead-root': {
    background: theme.palette.secondary.dark,
    color: theme.palette.text.quartenery,

    '.MuiTableCell-root': {
      borderBottom: 'none',
      paddingTop: '24px',
      paddingBottom: '20px',
    },
  },
  '.MuiTableBody-root': {
    background: theme.palette.primary.main,
  },
  '.MuiTableContainer-root': {
    background: theme.palette.primary.main,
    borderRadius: '20px',
  },
  '.MuiPaper-root': {
    background: theme.palette.primary.main,
    borderRadius: '20px',
  },
  '.MuiButton-root': {
    padding: '12px 20px',
    boxShadow: 'none',
    borderRadius: '4px',
  },
  '.MuiButton-outlined': {
    borderColor: theme.palette.blue.main,
    color: theme.palette.blue.main,

    '&:hover': {
      borderColor: theme.palette.blue.main,
    },
  },
  '.MuiButton-contained': {
    background: theme.palette.blue.main,
    color: `${theme.palette.white.main} !important`,

    '&:hover': {
      background: theme.palette.blue.main,
    },
  },
}));

export const Container = styled(BaseContainer)(({ ismobile }) => ({
  maxWidth: '100% !important',
  width: ismobile === 'true' ? 'calc(100% - 70px)' : 'calc(100% - 320px)',
  margin: ismobile === 'true' ? '40px 40px 88px 30px' : '60px 40px 88px 30px',
  padding: '0 !important',
}));
