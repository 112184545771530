import { CssBaseline } from '@mui/material';
import ThemeProvider from './Provider/ThemeProvider';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

import App from './App';
import { store, persistor } from './redux';
import reportWebVitals from './reportWebVitals';
import { UserProvider } from './utils/webClientsContext';
import NodeInfoProvider from './Provider/NodeInfoProvider';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <ThemeProvider>
        <UserProvider>
          <NodeInfoProvider>
            <CssBaseline />
            <App />
          </NodeInfoProvider>
        </UserProvider>
      </ThemeProvider>
    </PersistGate>
  </Provider>,
);

reportWebVitals();
