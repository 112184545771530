/*eslint-disable */
import { createSlice } from '@reduxjs/toolkit';
import { toast } from '../../components/Toast';

const initialState = {
  loading: false,
  isLoggedIn: false,
  user: {
    name: '',
    address: '',
    balance: '',
  },
  error: '',
  showUserBalance: '',
  balance: {
    totalBalance: '',
    lockedBalance: '',
    availableBalance: '',
    reservedBalance: '',
  },
  bondBalance: {
    bondedAmount: '',
    unbondedAmount: '',
  },
  dashboardStatus: {
    commission: '',
    status: '',
  },
  aprValue: '',
  userName: '',
};

export const authSlice = createSlice({
  name: 'drawer',
  initialState,
  reducers: {
    attemptLogIn: (state) => {
      state.loading = true;
    },
    logInSuccess: (state, action) => {
      state.loading = false;
      state.isLoggedIn = true;
      state.user = action.payload;
    },
    nameNotExist: (action) => {
      alert('Action');
      return;
      if (!action.payload.name) {
        toast.error('Enter Name');
      }
    },
    isNameExist: (state) => {
      state.isLoggedIn = true;
    },
    getUserBalance: (state, action) => {
      state.showUserBalance = action.payload;
    },
    getUsersBalance: (state, action) => {
      state.balance = action.payload;
    },
    setWalletAddress: (state, action) => {
      state.user.address = action.payload;
    },
    logout: (state) => {},
    getBondBalance: (state, action) => {
      state.bondBalance = action.payload;
    },
    getDashboardStatus: (state, action) => {
      state.dashboardStatus = action.payload;
    },
    getAprValue: (state, action) => {
      state.aprValue = action.payload;
    },
    getUserName: (state, action) => {
      state.userName = action.payload;
    },
  },
});

export const {
  attemptLogIn,
  logInSuccess,
  isNameExist,
  nameNotExist,
  logout,
  getUserBalance,
  getUsersBalance,
  getBondBalance,
  getDashboardStatus,
  getAprValue,
  setWalletAddress,
  getUserName,
} = authSlice.actions;

export default authSlice.reducer;
