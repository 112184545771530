import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { API_URL, BASE_URL } from '../../constant';

export const validatorApi = createApi({
  reducerPath: 'validatorApi',
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL.VALIDATOR_BASE_URL,
  }),
  endpoints: (builder) => ({
    getAllBlocks: builder.query({
      query: (data) => {
        return {
          url: API_URL.GET_ALL_BLOCKS.replace('{{pageNo}}', data.pageNo).replace(
            '{{limit}}',
            data.limit,
          ),
        };
      },
    }),
    // getTotalReward: builder.query({
    //   query: (address) => {
    //     return {
    //       url: API_URL.TOTAL_REWARD.replace('{{userAddress}}', address),
    //     };
    //   },
    // }),
    // getReward: builder.query({
    //   query: (address) => {
    //     return {
    //       url: API_URL.REWARD.replace('{{userAddress}}', address),
    //     };
    //   },
    // }),
    getRecentAction: builder.query({
      query: (address) => {
        return {
          // url: `get-transaction-by-address?address=${address}`,
          url: API_URL.RECENT_ACTION.replace('{{userAddress}}', address),
        };
      },
    }),
  }),
});

export const { useGetAllBlocksQuery, useGetRecentActionQuery } = validatorApi;
