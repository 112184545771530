import FlexBox from '../FlexBox';
import Tooltip from '../ToolTip';
import { Typography, Box } from '@mui/material';
import InfoIcon from '../../../assets/Icons/InfoIcon';

const WithToolTip = ({ children, tooltipContent, ...props }) => {
  return (
    <FlexBox {...props}>
      {children}
      {tooltipContent ? (
        <Tooltip followCursor title={<Typography variant="subtitle2">{tooltipContent}</Typography>}>
          <Box
            sx={{
              position: 'relative',
              display: 'flex',
              paddingLeft: '6px',
              '.MuiSvgIcon-root': {
                fontSize: '16px !important',
                cursor: 'pointer !important',
                fill: 'transparent !important',
              },
            }}
          >
            <InfoIcon />
          </Box>
        </Tooltip>
      ) : null}
    </FlexBox>
  );
};

export default WithToolTip;
