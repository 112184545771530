/*eslint-disable*/

import { useEffect, useState } from 'react';
import axios from 'axios';
import { toast } from '../components/Toast';

export function useGetApr() {
  const [aprValue, setAprValue] = useState('');

  useEffect(() => {
    getAprValue();
  }, []);

  const getAprValue = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_BASE_URL_SCAN_API}explorer/apy`);
      if (res) {
        setAprValue(res?.data?.data?.avgApy);
      }
    } catch (error) {
      toast.error(error);
    }
  };

  return { aprValue };
}
