/*eslint-disable*/
import { configureStore } from '@reduxjs/toolkit';
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';
import storageSession from 'redux-persist/lib/storage/session';
import storage from 'redux-persist/lib/storage';
import rootReducer from './rootReducer';
import { validatorApi } from './Api/validatorApi';
import { LoginApi } from './Api/LoginApi';

const persistConfig = {
  key: 'root',
  version: 1,
  storage: storage,
  blacklist: ['validatorApi', 'LoginApi'],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(validatorApi.middleware, LoginApi.middleware),
});

export const persistor = persistStore(store);
