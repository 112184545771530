import { SvgIcon } from '@mui/material';

const DarkModeIcon = (props) => {
  return (
    <SvgIcon viewBox="0 0 56 48" {...props}>
      <g filter="url(#filter0_d_721_1903)">
        <rect x="8" y="7" width="40" height="32" rx="16" fill="white" />
        <g clipPath="url(#clip0_721_1903)">
          <path
            d="M22.76 15.84L20.96 14.05L19.55 15.46L21.34 17.25L22.76 15.84ZM17 21.5H20V23.5H17V21.5ZM27 11.55H29V14.5H27V11.55ZM35.04 14.045L36.448 15.452L34.658 17.242L33.251 15.834L35.04 14.045ZM33.24 29.16L35.03 30.96L36.44 29.55L34.64 27.76L33.24 29.16ZM36 21.5H39V23.5H36V21.5ZM28 16.5C24.69 16.5 22 19.19 22 22.5C22 25.81 24.69 28.5 28 28.5C31.31 28.5 34 25.81 34 22.5C34 19.19 31.31 16.5 28 16.5ZM28 26.5C25.79 26.5 24 24.71 24 22.5C24 20.29 25.79 18.5 28 18.5C30.21 18.5 32 20.29 32 22.5C32 24.71 30.21 26.5 28 26.5ZM27 30.5H29V33.4501H27V30.5ZM19.55 29.5401L20.96 30.9501L22.75 29.15L21.34 27.74L19.55 29.5401Z"
            fill="#333333"
          />
        </g>
      </g>
      <defs>
        <filter
          id="filter0_d_721_1903"
          x="0"
          y="0"
          width="56"
          height="48"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="4" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_721_1903" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_721_1903"
            result="shape"
          />
        </filter>
        <clipPath id="clip0_721_1903">
          <rect width="24" height="24" fill="white" transform="translate(16 11)" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};

export default DarkModeIcon;
