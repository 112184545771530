import { SvgIcon, useTheme } from '@mui/material';

const ArrowRightIcon = (props) => {
  const theme = useTheme();
  return (
    <SvgIcon viewBox="0 0 25 24" {...props}>
      <path
        d="M16.3297 12.8192L9.70285 17.458C8.77496 18.1075 7.5 17.4437 7.5 16.3111V7.68892C7.5 6.55628 8.77496 5.89247 9.70285 6.54199L16.3297 11.1808C16.8984 11.5789 16.8984 12.4211 16.3297 12.8192Z"
        fill={theme.palette.blue.main}
      />
    </SvgIcon>
  );
};

export default ArrowRightIcon;
