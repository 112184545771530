import { SvgIcon } from '@mui/material';

const InfoIcon = (props) => {
  return (
    <SvgIcon viewBox="0 0 16 17" {...props}>
      <circle cx="8" cy="8.5" r="6" stroke="#6590CD" strokeWidth="2" />
      <path
        d="M8.33366 5.50002C8.33366 5.68412 8.18442 5.83335 8.00033 5.83335C7.81623 5.83335 7.66699 5.68412 7.66699 5.50002C7.66699 5.31593 7.81623 5.16669 8.00033 5.16669C8.18442 5.16669 8.33366 5.31593 8.33366 5.50002Z"
        fill="#6590CD"
        stroke="#6590CD"
      />
      <path d="M8 11.8334V7.16669" stroke="#6590CD" strokeWidth="2" />
    </SvgIcon>
  );
};

export default InfoIcon;
