import { Box, styled } from '@mui/material';
import { Link } from 'react-router-dom';

export const Wrapper = styled(Box)(({ isopen, theme }) => ({
  minHeight: '100vh',
  width: '250px',
  background: theme.palette.primary.main,
  padding: '60px 0px 80px 0px',
  display: 'flex',
  flexDirection: 'column',
  flexShrink: '0',
  zIndex: '20',
  justifyContent: 'space-between',
  overflowY: 'auto',
  [theme.breakpoints.down('md')]: {
    position: 'absolute',
    padding: '20px 0px 80px 0px',
    left: 0,
    transform: isopen === 'true' ? 'translateX(0%)' : 'translateX(-100%)',
    top: 0,
    transition: 'all .5s',
  },
}));

export const WalletDetails = styled(Box)(({ theme }) => ({
  paddingTop: '33px',
  paddingBottom: '20px',
  borderBottom: `1px solid ${theme.palette.divider}`,
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',

  '.MuiSvgIcon-root': {
    fill: 'transparent',
    flexShrink: '0',
  },
}));

export const NavItem = styled(Link)(({ theme, active }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: '10.5px 21px 10.5px 44px',
  textDecoration: 'none',
  background: active === 'true' ? theme.palette.blue.main : 'none',
  color: active === 'true' ? theme.palette.white.main : theme.palette.text.primary,

  '.MuiSvgIcon-root': {
    flexShrink: '0',
    fontSize: '20px',
    path: {
      fill: active === 'true' ? theme.palette.white.main : theme.palette.text.primary,
    },
  },
}));

export const SubLink = styled(Link)(({ theme, active }) => ({
  ...theme.typography.body2,
  textDecoration: 'none',
  textTransform: 'capitalize',
  color:
    active === 'true'
      ? `${theme.palette.blue.main} !important`
      : `${theme.palette.text.secondary} !important`,
  fontWeight: active === 'true' ? `700 !important` : `400`,
}));

export const AddressWrapper = styled('button')(({ theme }) => ({
  background: theme.palette.secondary.darker,
  display: 'flex',
  borderRadius: '8px',
  justifyContent: 'center',
  padding: '6px 34px',
  border: 'none',
  alignItems: 'center',
  cursor: 'pointer',

  '.MuiSvgIcon-root': {
    fill: 'transparent',
  },
}));
