import { showNum } from '../constant';

export const formatNumber = (num) => {
  let result;
  switch (true) {
    case num >= showNum.TRILLION:
      result = (num / showNum.TRILLION).toFixed(2) + ' T';
      break;
    case num >= showNum.BILLION:
      result = (num / showNum.BILLION).toFixed(2) + ' B';
      break;
    case num >= showNum.MILLION:
      result = (num / showNum.MILLION).toFixed(2) + ' M';
      break;
    case num >= showNum.THOUSAND:
      result = (num / showNum.THOUSAND).toFixed(2) + ' K';
      break;
    default:
      result = num.toString();
      break;
  }
  return result;
};
