import { SvgIcon, useTheme } from '@mui/material';

const LogoutIcon = (props) => {
  const theme = useTheme();

  return (
    <SvgIcon viewBox="0 0 24 24" {...props}>
      <path
        stroke={theme.palette.text.primary}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="1.5"
        d="M17.44 14.62L20 12.06 17.44 9.5M9.76 12.06h10.17M11.76 20c-4.42 0-8-3-8-8s3.58-8 8-8"
      ></path>
    </SvgIcon>
  );
};

export default LogoutIcon;
