/*eslint-disable*/
import { DECIMAL } from '../constant';

export const getBalance = async (address, api) => {
  if (address) {
    const data = await api?.derive?.balances?.all(address);
    const userBalance = {
      total: `${data?.freeBalance}` / 10 ** Number(DECIMAL),
      lockedBalance: `${data?.lockedBalance}` / 10 ** Number(DECIMAL),
      availableBalance: `${data?.availableBalance}` / 10 ** Number(DECIMAL),
      reservedBalance: `${data?.reservedBalance}` / 10 ** Number(DECIMAL),
    };
    return userBalance;
  }
};
