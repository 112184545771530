import { SvgIcon } from '@mui/material';

const MarketCapIcon = () => {
  return (
    <SvgIcon viewBox="0 0 40 40">
      <rect x="0.5" y="0.5" width="39" height="39" rx="2.5" stroke="#87BCFF" strokeOpacity="0.7" />
      <path
        d="M32 29.3333V12C32 10.5333 30.8 9.33334 29.3333 9.33334H25.3333C23.8667 9.33334 22.6667 10.5333 22.6667 12V14.6667H18.6667C17.2 14.6667 16 15.8667 16 17.3333V18.6667H12C10.5333 18.6667 9.33333 19.8667 9.33333 21.3333V29.3333C8.53333 29.3333 8 29.8667 8 30.6667C8 31.4667 8.53333 32 9.33333 32H10.6667H17.3333H24H30.6667H32C32.8 32 33.3333 31.4667 33.3333 30.6667C33.3333 29.8667 32.8 29.3333 32 29.3333ZM12 21.3333H16V29.3333H12V21.3333ZM18.6667 21.3333V17.3333H22.6667V29.3333H18.6667V21.3333ZM25.3333 29.3333V17.3333V12H29.3333V29.3333H25.3333Z"
        fill="#87BCFF"
        fillOpacity="0.7"
      />
    </SvgIcon>
  );
};

export default MarketCapIcon;
