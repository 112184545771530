/*eslint-disable*/
import { Box, Typography, useTheme } from '@mui/material';
import { Wrapper, StatItem } from './styles';
import MarketCapIcon from '../../assets/Icons/MarketCapIcon';
import PriceIcon from '../../assets/Icons/PriceIcon';
import RewardIcon from '../../assets/Icons/RewardIcon';
import SupplyIcon from '../../assets/Icons/SupplyIcon';
import { toFixed } from '../../services/toFixed';
import Tooltip from '../Common/ToolTip';
import WithToolTip from '../Common/WithToolTip';
import { useGetWidgetsData } from '../../hooks/useGetWidgetsData';
import { formatNumber } from '../../utils/formatNumber';
import { useGetApr } from '../../hooks/useGetApr';

const Statistics = () => {
  const { aprValue } = useGetApr();
  const { fireData } = useGetWidgetsData();

  const theme = useTheme();

  return (
    <Wrapper>
      <StatItem>
        <MarketCapIcon />
        <Box ml="20px">
          <WithToolTip tooltipContent="Total value of all the coins issued">
            <Typography variant="h6" color={theme.palette.blue.light}>
              MARKET CAP
            </Typography>
          </WithToolTip>
          <Tooltip title={Number(fireData?.marketCap) || ''}>
            <Typography
              variant="h4"
              mt="2px"
              color={theme.palette.white.main}
              sx={{ cursor: 'pointer' }}
            >
              {fireData?.marketCap ? formatNumber(Number(fireData?.marketCap)) : '0'}
            </Typography>
          </Tooltip>
        </Box>
      </StatItem>
      <StatItem>
        <PriceIcon />
        <Box ml="20px">
          <Typography variant="h6" color={theme.palette.blue.light}>
            5IRE PRICE
          </Typography>
          <Tooltip title={Number(fireData?.price) || ''}>
            <Typography
              variant="h4"
              fontWeight="600"
              mt="2px"
              color={theme.palette.white.main}
              sx={{ cursor: 'pointer' }}
            >
              ${fireData?.price ? Number(fireData?.price).toFixed(4) : '0'}
            </Typography>
          </Tooltip>
        </Box>
      </StatItem>
      <StatItem>
        <RewardIcon />
        <Box ml="20px">
          <WithToolTip
            tooltipContent="The annual percentage yield in 5ire is calculated by dividing the estimated yearly
                  reward by the current 5ire (5irechain native token) holding."
          >
            <Typography variant="h6" color={theme.palette.blue.light}>
              APY
            </Typography>
          </WithToolTip>
          <Tooltip title={aprValue || 0}>
            <Typography variant="h4" fontWeight="600" mt="2px" color={theme.palette.white.main}>
              {aprValue > 0 ? formatNumber(toFixed(aprValue, 4)) : 0} % &nbsp;
            </Typography>
          </Tooltip>
        </Box>
      </StatItem>
      <StatItem>
        <SupplyIcon />
        <Box ml="20px">
          <WithToolTip tooltipContent="Total tokens issued by the system">
            <Typography variant="h6" color={theme.palette.blue.light}>
              Circulating Supply
            </Typography>
          </WithToolTip>
          <Tooltip title={Number(fireData?.supply) || ''}>
            <Typography
              variant="h5"
              mt="2px"
              color={theme.palette.white.main}
              sx={{ cursor: 'pointer' }}
            >
              {fireData?.supply ? formatNumber(Number(fireData?.supply)) : '0'}
            </Typography>
          </Tooltip>
        </Box>
      </StatItem>
    </Wrapper>
  );
};

export default Statistics;
