/*eslint-disable*/
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { API_URL } from '../../constant';
import { BASE_URL } from '../../constant';

export const LoginApi = createApi({
  reducerPath: 'LoginApi',
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL.VALIDATOR_BASE_URL,
  }),
  endpoints: (builder) => ({
    createName: builder.mutation({
      query: (data) => {
        return {
          url: API_URL.ADD_NAME,
          method: 'POST',
          body: data,
          headers: {
            'Content-type': 'application/json; charset=UTF-8',
          },
        };
      },
    }),
    getLoginData: builder.query({
      query: (address) => ({
        url: API_URL.GET_LOGIN_DATA.replace('{{userAddress}}', address),
      }),
    }),
    getValidatorList: builder.query({
      query: () => ({
        url: API_URL.ALL_VALIDATOR_LIST,
      }),
    }),
  }),
});

export const { useCreateNameMutation, useGetLoginDataQuery, useGetValidatorListQuery } = LoginApi;
