const getTheme = (mode) => ({
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        html: {
          '& ::-webkit-scrollbar': {
            width: '5px',
            height: '5px',
          },
          '& ::-webkit-scrollbar-track': {
            boxShadow: `inset 0 0 5px #fff`,
            borderRadius: '10px',
          },
          '& ::-webkit-scrollbar-thumb': {
            backgroundColor: mode === 'light' ? '#0F2046' : '#0071FF',
            borderRadius: '10px',
          },
          '& ::-webkit-scrollbar-thumb:hover': {},
        },
      },
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 767,
      md: 999,
      lg: 1239,
      xl: 1488,
    },
  },
  typography: {
    fontSize: 11,
    fontFamily: 'poppins',
    fontWeightRegular: 'normal',
    h1: {
      fontSize: 29,
      fontWeight: 700,
    },
    h2: {
      fontSize: 36,
      lineHeight: 40,
      fontWeight: 500,
    },
    h3: {
      fontSize: 24,
      lineHeight: '36px',
      fontWeight: 600,
      letterSpacing: 0.5,
    },
    h4: {
      fontSize: 22,
      fontWeight: 600,
      lineHeight: '33px',
    },
    h5: {
      fontSize: 20,
      fontWeight: 500,
      lineHeight: '26px',
    },
    h6: {
      fontSize: 16,
      fontWeight: 400,
      lineHeight: '24px',
      letterSpacing: 0.5,
    },
    subtitle1: {
      fontSize: 14,
      fontWeight: 400,
    },
    subtitle2: {
      fontSize: 13,
      fontWeight: 400,
    },
    body1: {
      fontSize: 18,
      lineHeight: '26px',
      letterSpacing: -0.25,
    },
    body2: {
      fontSize: 16,
      lineHeight: '24px',
    },
    body3: {
      fontSize: 12,
      lineHeight: '18px',
    },
    label: {
      fontSize: 14,
      lineHeight: 2,
      fontWeight: 500,
    },
    caption: {
      fontSize: 10,
    },
    button: {
      fontWeight: '700',
      fontSize: 14,
      lineHeight: 1.6,
      letterSpacing: 1.5,
      textTransform: 'uppercase',
    },
  },
  palette: {
    mode,
    ...(mode === 'light'
      ? {
          primary: { main: '#fff', dark: '#F8F8F8' },
          secondary: { main: '#152A59', dark: '#EEEEEE', darker: '#EEEEEE', light: '#EEEEEE' },
          blue: {
            main: '#0F2046',
            darker: '#2F4880',
            dark: '#0F2046',
            light: '#87BCFF',
            lighter: '#0071FF',
          },
          gray: { light: '#9E9E9E', main: '#E8E8E8', dark: '#757575' },
          green: { main: '#24FF00' },
          orange: { main: '#FF9900' },
          white: { main: '#FFFFFF' },
          text: {
            primary: '#707070',
            quartenery: '#181818',
            secondary: '#181818',
            tertiary: '#0F2046',
          },
          info: { main: '#9E9E9E', light: '#707070', lighter: '#EEEEEE', dark: '#0F2046' },
          divider: 'rgba(112, 112, 112, 0.3)',
          red: { main: '#FF0000' },
        }
      : {
          // palette values for dark mode
          primary: { main: '#0F2046', dark: '#08132C' },
          secondary: { main: '#152A59', dark: '#192A56', darker: '#08132C', light: '#152A59' },
          blue: {
            main: '#0071FF',
            darker: '#2F4880',
            dark: '#0F2046',
            light: '#87BCFF',
            lighter: '#0071FF',
          },
          gray: { light: '#9999B3', main: '#E8E8E8', dark: '#757575' },
          green: { main: '#24FF00' },
          orange: { main: '#FF9900' },
          white: { main: '#FFFFFF' },
          text: {
            primary: '#FFFFFF',
            quartenery: '#FFFFFF',
            secondary: '#87BCFF',
            tertiary: '#FFFFFF',
          },
          info: { main: '#2F4880', light: '#87BCFF', lighter: '#87BCFF', dark: '#87BCFF' },
          divider: 'rgba(135, 188, 255, 0.3)',
          red: { main: '#FF0000' },
        }),
  },
});

export default getTheme;
