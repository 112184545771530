/* eslint-disable no-unused-vars */
import { Box, styled } from '@mui/material';
import InfoIcon from '../../assets/Icons/SecondaryInfoIcon';

export const Wrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  column: '16px',
  gap: '25px',
  width: '100%',
  flexWrap: 'wrap',
  marginBottom: '40px',
  justifyContent: 'space-between',

  // [theme.breakpoints.down(540)]: {
  //   display: 'grid',
  // },
}));

export const StatItem = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  position: 'relative',
  flexShrink: '0',
  background: theme.palette.secondary.main,
  borderRadius: '16px',
  padding: '14.5px 20px',
  h4: {
    lineHeight: '33px !important',
  },
  h5: {
    lineHeight: '33px !important',
  },
  '.MuiSvgIcon-root': {
    fontSize: '40px',
    fill: 'transparent',
    flexShrink: '0',
  },
  [theme.breakpoints.down(540)]: {
    width: '100%',
  },
}));

export const InfoIconWrapper = styled(Box)({
  position: 'relative',
  display: 'flex',
  paddingLeft: 2,
  '.MuiSvgIcon-root': {
    fontSize: '16px !important',
    cursor: 'pointer !important',
  },
});
