/*eslint-disable*/
import { lazy, Suspense, useEffect, useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Route, Routes } from 'react-router-dom';
import MainPanel from '../components/MainPanel';
import PrivateRouter from './PrivateRouter';
import { getApiData } from '../utils/webClientsContext';
import { useLocation } from 'react-router-dom';
import { logout, setWalletAddress } from '../redux/reducer/auth';
import { useDispatch } from 'react-redux';
import FundsModal from '../components/FundsModal';
import NodeInfoModal from '../components/NodeInfoModal';
import axios from 'axios';
import { useCreateNameMutation } from '../redux/Api/LoginApi';
import { toast } from '../components/Toast';
import useNodeInfoContext from '../hooks/useNodeInfoContext';
import { logInSuccess } from '../redux/reducer/auth';
import { getUserName } from '../redux/reducer/auth';
const Login = lazy(() => import('../pages/Login'));
const Dashboard = lazy(() => import('../pages/Dashboard'));
const BlocksPage = lazy(() => import('../pages/Blocks'));
const Validators = lazy(() => import('../pages/Validators'));
const Profile = lazy(() => import('../pages/Account/Profile'));
const Network = lazy(() => import('../pages/Account/Network'));
const Funds = lazy(() => import('../pages/Account/Funds'));
const Bond = lazy(() => import('../pages/Account/Funds/Bond'));
const UnBond = lazy(() => import('../pages/Account/Funds/UnBond'));
const ManageAccount = lazy(() => import('../pages/Account/Funds/ManageAccount'));
const Withdraw = lazy(() => import('../pages/Account/Funds/Withdraw'));
const Payout = lazy(() => import('../pages/Account/Funds/Payout'));
const StopValidating = lazy(() => import('../pages/Account/Funds/StopValidating'));
const SetupValidator = lazy(() => import('../pages/Account/SetupValidator'));
const StartValidator = lazy(() => import('../pages/Account/Funds/RestartValidator/'));
const AuthorizePayout = lazy(() => import('../components/Funds/Payout/AuthorizeTransaction'));
const ValidatorDetails = lazy(() => import('../pages/Validators/EachValidator/index'));
const AccountSetup = lazy(() => import('../pages/Login/AccountSetup'));
import { useNavigate } from 'react-router-dom';

const RoutePath = () => {
  const { setIsNodeExist } = getApiData();

  const { isLoggedIn } = useSelector((state) => state.auth);
  const [address, setAddress] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const { nodeIp, api } = getApiData();
  const { nodeInfo, setNodeInfo } = useNodeInfoContext();
  const location = useLocation();
  const [isOpenedNodeInfo, setIsOpenedNodeInfo] = useState(false);
  const [createNameList, getError] = useCreateNameMutation();
  const dispatch = useDispatch();
  useEffect(() => {}, [nodeIp, api]);
  const navigate = useNavigate();

  const handleNodeInfo = (_nodeInfo) => {
    if (!_nodeInfo) {
      toast.error('Enter Node Url');
      return;
    }
    if (
      _nodeInfo?.includes('ws://') ||
      _nodeInfo?.includes('https://') ||
      _nodeInfo?.includes('http://') ||
      _nodeInfo?.includes('wss://')
    ) {
      setNodeInfo(_nodeInfo);
      setIsOpenedNodeInfo(false);
      window.location.reload();
    } else {
      toast.error('Invalid Node URL');
    }
  };

  useEffect(() => {
    checkValidatorExist();
  }, [location.pathname, address]);

  const checkValidatorExist = () => {
    try {
      if (window.fire) {
        window.fire.on('accountChanged', async (res) => {
          const data = await res;
          // const user = {
          //   name: '',
          //   wallet_address: data.nativeAddress,
          //   avatar: '',
          //   total_stake_amount: 0,
          // };
          // await createNameList(user);
          // const result = await axios.get(
          //   `${process.env.REACT_APP_BASE_URL_SCAN_API}validator/${data?.nativeAddress}`,
          // );
          // const user = {
          //   name: result?.data?.data?.name,
          //   address: result?.data?.data?.wallet_address,
          // };
          sessionStorage.clear();
          dispatch(logout());
          // toast.error('Session Expired');
          setIsNodeExist(false);
          navigate('/login');
          window?.fire?.removeAllListeners();
          window.location.reload();

          // dispatch(logInSuccess(user));
          // dispatch(setWalletAddress(data?.nativeAddress));
          // window.fire.disconnect().catch(console.log);
          // dispatch(logout());
          // .then((res) => {
          //   if (res) {
          //     dispatch(getUserName(res?.data?.message?.data?.name));
          //     // dispatch(logInSuccess(userData));
          //   }
          // });

          // dispatch(setWalletAddress(data.nativeAddress));
          // setIsOpenedNodeInfo(true);
        });
      } else {
        return;
        // toast.error('Check your wallet extension');
      }
    } catch (error) {
      sessionStorage.clear();
      dispatch(logout());
      // toast.error('Session Expired');
      setIsNodeExist(false);
      navigate('/login');
      window?.fire?.removeAllListeners();
      window.location.reload();
    }
  };

  return (
    <>
      <MainPanel>
        <Suspense fallback>
          <Routes>
            {/* <Route
              path="/account-setup"
              element={!isLoggedIn ? <AccountSetup /> : <Navigate to="/dashboard" replace />}
            /> */}
            <Route path="/" element={<Navigate to="/blocks" replace />} />
            <Route path="/blocks" element={<BlocksPage />} />
            <Route path="/validators" element={<Validators />} />
            <Route
              path="/account/profile"
              element={
                <PrivateRouter>
                  <Profile />
                </PrivateRouter>
              }
            />
            <Route
              path="/account/network"
              element={
                <PrivateRouter>
                  <Network />
                </PrivateRouter>
              }
            />
            <Route
              path="/account/become-a-validator"
              element={
                <PrivateRouter>
                  <SetupValidator />
                </PrivateRouter>
              }
            />
            <Route
              path="/account/payout-authorize"
              element={
                <PrivateRouter>
                  <AuthorizePayout />
                </PrivateRouter>
              }
            />
            <Route
              path="/validators/:address"
              element={
                <PrivateRouter>
                  <ValidatorDetails />
                </PrivateRouter>
              }
            />

            <Route
              path="/account/funds"
              element={
                <PrivateRouter>
                  <Funds />
                </PrivateRouter>
              }
            >
              <Route path="/account/funds" index element={<ManageAccount />} />
              <Route path="/account/funds/bond" element={<Bond />} />
              <Route path="/account/funds/unbond" element={<UnBond />} />
              <Route path="/account/funds/withdraw" element={<Withdraw />} />
              <Route path="/account/funds/stop-validating" element={<StopValidating />} />
              <Route path="/account/funds/start-validating" element={<StartValidator />} />
            </Route>
            <Route
              path="/dashboard"
              element={
                <PrivateRouter>
                  <Dashboard />
                </PrivateRouter>
              }
            />
            <Route path="*" element={<Navigate to="/blocks" replace />} />
          </Routes>
        </Suspense>
      </MainPanel>
      {isOpenedNodeInfo && <NodeInfoModal isOpen={isOpenedNodeInfo} handleClose={handleNodeInfo} />}
    </>
  );
};

export default RoutePath;
