import { styled } from '@mui/material';
import FlexBox from '../Common/FlexBox';

export const Wrapper = styled(FlexBox)(({ theme }) => ({
  background: theme.palette.mode === 'light' ? theme.palette.secondary.light : '',
  justifyContent: 'space-between',
  padding: '8px 16px',
  width: '112px',
  borderRadius: '100px',
  cursor: 'pointer',
  height: '48px',
  marginBottom: 44,
  '> :first-of-type': {
    fontSize: '45px',
    rect: {
      fill: theme.palette.mode === 'dark' ? theme.palette.primary.dark : '',
    },
    path: {
      fill: theme.palette.mode === 'dark' ? theme.palette.white.main : '',
    },
  },
  '> :nth-of-type(2)': {
    fontSize: '25px',
  },
}));
