import DarkModeIcon from '../../assets/Icons/DarkModeIcon';
import LightModeIcon from '../../assets/Icons/LightModeIcon';
import useColorMode from '../../hooks/useColorMode';
import { Wrapper } from './styles';

const ThemeSwitcher = () => {
  const { toggleColorMode } = useColorMode();
  return (
    <Wrapper onClick={toggleColorMode}>
      <DarkModeIcon />
      <LightModeIcon />
    </Wrapper>
  );
};

export default ThemeSwitcher;
