/*eslint-disable*/
import { DialogActions, Typography } from '@mui/material';
import { BackButton, CancelIconWrapper, DialogContent, StyledDialog } from './styles';
import ConnectionErrorImage from '../../assets/Images/ConnectionError.png';
import CancelIcon from '../../assets/Icons/SecondaryCancelIcon';
import ArrowBackIcon from '../../assets/Icons/ArrowBackIcon';

const ConnectionErrorModal = ({ connectionOpen, connectionClose }) => {
  return (
    <StyledDialog
      open={connectionOpen}
      onClose={connectionClose}
      aria-labelledby="logout-dialog-title"
      aria-describedby="logout-dialog-description"
    >
      <CancelIconWrapper>
        <CancelIcon onClick={connectionClose} />
      </CancelIconWrapper>
      <DialogContent>
        <img src={ConnectionErrorImage} alt="connection error" />
        <Typography mt={4.8} variant="h5">
          Something went wrong.
        </Typography>
        <Typography variant="body2" mt={1.6}>
          The connection attempt failed. Please connect your wallet and try again.
        </Typography>
      </DialogContent>
      {/*
      <DialogActions>
        <BackButton>
          <ArrowBackIcon />
          <Typography component="span" ml={2.4}>
            Back to previous page
          </Typography>
        </BackButton>
      </DialogActions>
      */}
    </StyledDialog>
  );
};

export default ConnectionErrorModal;
