import { Typography } from '@mui/material';

import { Wrapper } from './styles';
import getData from '../../../utils/getData';

import CopyIcon from '../../../assets/Icons/CopyIcon';
import { useSelector } from 'react-redux';
import { toast } from '../../Toast';

const Address = ({ variant, address, ...props }) => {
  const Mnemonicaddress = useSelector((state) => state.auth.user);

  const copyAddress = () => {
    navigator.clipboard.writeText(Mnemonicaddress.address || address);
    toast.success('Address Copied');
  };

  return (
    <Wrapper {...props}>
      <Typography mr="16px" variant={variant || 'body2'} color="text.quartenery">
        {getData(address)}
      </Typography>
      <CopyIcon sx={{ fontSize: '24px', cursor: 'pointer' }} onClick={copyAddress} />
    </Wrapper>
  );
};

export default Address;
