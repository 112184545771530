/*eslint-disable*/

export const DEFAULT_FLAGS_ELECTED = {
  withController: true,
  withExposure: true,
  withPrefs: true,
};

export const DEFAULT_FLAGS_WAITING = {
  withController: true,
  withPrefs: true,
};

/*PAGE LIMIT*/
export const PAGE_LIMIT = 10;

/*DECIMAL VALUE*/
export const DECIMAL = process.env.REACT_APP_DECIMAL;

/*BASE URL*/
export const BASE_URL = {
  VALIDATOR_BASE_URL: process.env.REACT_APP_BASE_URL_SCAN_API,
  FIRECHAIN_BASE_URL: process.env.REACT_APP_FIRECHAIN_EXPLORER,
};

/*WALLET URL FOR CREATING NEW ACCOUNT*/
export const WALLET_URL = {
  CREATE_WALLET: process.env.REACT_APP_CREATE_WALLET,
  VIEW_ALL_BLOCKS: process.env.REACT_APP_FIRECHAIN_EXPLORER,
};

/*API URL*/
export const API_URL = {
  ALL_VALIDATOR_LIST: 'validator',
  GET_LOGIN_DATA: 'validator/{{userAddress}}',
  GET_ALL_BLOCKS: 'blocks?page={{pageNo}}&limit={{limit}}',
  ADD_NAME: 'validator/add',
  TOTAL_REWARD: 'get-recent-total-reward-by-address/{{userAddress}}',
  REWARD: 'get-reward-by-address/{{userAddress}}',
  RECENT_ACTION: 'get-transaction-by-address?address={{userAddress}}',
};

/*Show number in million / billion / trillion format*/
export const showNum = {
  TRILLION: 1e12,
  BILLION: 1e9,
  MILLION: 1e6,
  THOUSAND: 1e3,
};

export const MIN_STAKE = process.env.REACT_APP_MIN_STAKE_AMOUNT;
