import { SvgIcon, useTheme } from '@mui/material';

const DashboardIcon = () => {
  const theme = useTheme();

  return (
    <SvgIcon viewBox="0 0 24 24">
      <rect
        x="4"
        y="4"
        width="6"
        height="6"
        rx="1"
        stroke={theme.palette.text.primary}
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <rect
        x="4"
        y="14"
        width="6"
        height="6"
        rx="1"
        stroke={theme.palette.text.primary}
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <rect
        x="14"
        y="14"
        width="6"
        height="6"
        rx="1"
        stroke={theme.palette.text.primary}
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <rect
        x="14"
        y="4"
        width="6"
        height="6"
        rx="1"
        stroke={theme.palette.text.primary}
        strokeWidth="2"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};

export default DashboardIcon;
