/*eslint-disable*/
import React, { createContext, useContext, useState, useEffect } from 'react';
import { ApiPromise, WsProvider, HttpProvider } from '@polkadot/api';
import io from 'socket.io-client';
const UsersContext = createContext();

const socket = io();

export const UserProvider = ({ children }) => {
  const [api, setApi] = useState(null);
  const [nodeIp, setNodeIp] = useState(null);
  const [isNodeExist, setIsNodeExist] = useState(false);
  const [isValidatorNode, setIsValidatorNode] = useState(false);

  const initialisingApi = async () => {
    try {
      let wsProvider =
        nodeIp.includes('https') || nodeIp.includes('http')
          ? new HttpProvider(nodeIp)
          : new WsProvider(nodeIp);

      if (nodeIp.includes('https') || nodeIp.includes('http')) {
        if (socket.connected == false) {
          setApi('error');
          return;
        }
      } else {
        wsProvider.on('error', (err) => {
          setApi('error');
          return;
        });
      }

      let apitemp = await ApiPromise.create({ provider: wsProvider });
      const checkNode = await apitemp.rpc.system.nodeRoles();
      if (checkNode.toHuman()[0] !== 'Authority') {
        setIsValidatorNode(true);
      }
      if (apitemp) {
        setApi(apitemp);
      } else {
        // alert('hi');
      }
    } catch (e) {
      return e;
    }
  };

  useEffect(() => {
    if (nodeIp !== null) {
      initialisingApi();
    }
  }, [nodeIp]);

  useEffect(() => {
    if (sessionStorage.getItem('nodeIp') !== null) {
      setNodeIp(sessionStorage.getItem('nodeIp'));
    }
  }, [sessionStorage.getItem('nodeIp')]);

  return (
    <UsersContext.Provider
      value={{ api, setApi, setNodeIp, nodeIp, setIsNodeExist, isNodeExist, isValidatorNode }}
    >
      {children}
    </UsersContext.Provider>
  );
};

export const getApiData = () => useContext(UsersContext);
