import { SvgIcon } from '@mui/material';

const LightModeIcon = (props) => {
  return (
    <SvgIcon viewBox="0 0 24 24" {...props}>
      <g clipPath="url(#clip0_721_1913)">
        <path
          d="M10 4C14.41 4 18 7.59 18 12C18 16.41 14.41 20 10 20C9.66 20 9.32 19.98 8.99 19.93C10.9 17.77 12 14.95 12 12C12 9.05 10.9 6.23 8.99 4.07C9.32 4.02 9.66 4 10 4ZM10 2C8.18 2 6.47 2.5 5 3.35C7.99 5.08 10 8.3 10 12C10 15.7 7.99 18.92 5 20.65C6.47 21.5 8.18 22 10 22C15.52 22 20 17.52 20 12C20 6.48 15.52 2 10 2Z"
          fill="#9E9E9E"
        />
      </g>
      <defs>
        <clipPath id="clip0_721_1913">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};

export default LightModeIcon;
