/*eslint-disable*/

import { useEffect, useState } from 'react';
import axios from 'axios';
import { toast } from '../components/Toast';

export function useGetWidgetsData() {
  const [fireData, setFireData] = useState('');

  useEffect(() => {
    getApiData();
  }, []);

  const getApiData = async () => {
    try {
      axios.get(process.env.REACT_APP_MARKET_CAP).then((res) => {
        if (res?.data?.data?.obj) {
          setFireData(res?.data?.data?.obj);
        }
      });
    } catch (error) {
      toast.error(error);
    }
  };

  return { fireData };
}
