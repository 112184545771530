import { DialogActions, Typography, Box, useTheme } from '@mui/material';
import ArrowRightObliqueUpIcon from '../../assets/Icons/ArrowRightObliqueUp';
import CopyIcon from '../../assets/Icons/CopyIcon';
import FlexBox from '../Common/FlexBox';
import { StyledDialog, DisconnectButton, StyledDivider, StyledLink } from './styles';
import { toast } from '../Toast';
import { useSelector } from 'react-redux';
import getData from '../../utils/getData';
import CancelIcon from '../../assets/Icons/SecondaryCancelIcon';

const DisconnectModal = ({ isOpen, logoutHandler, handleClose }) => {
  const theme = useTheme();
  const user = useSelector((state) => state.auth.user);

  const copyAddress = () => {
    navigator.clipboard.writeText(user?.address);
    toast.success('Address Copied');
  };

  return (
    <StyledDialog
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="logout-dialog-title"
      aria-describedby="logout-dialog-description"
    >
      <Box
        sx={{
          position: 'absolute',
          top: '22px',
          right: '24px',
        }}
      >
        <CancelIcon
          onClick={handleClose}
          sx={{
            cursor: 'pointer',
          }}
        />
      </Box>
      <Typography
        variant="label"
        fontWeight="700"
        color={theme.palette.mode === 'dark' ? theme.palette.blue.light : theme.palette.blue.light}
      >
        Account
      </Typography>
      {/*
      <Typography
        variant="h3"
        mt={1.2}
        color="text.tertiary"
        sx={{
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          maxWidth: 217,
        }}
      >
        {user?.name}
      </Typography>
      */}
      <StyledDivider />
      <DialogActions
        sx={{
          padding: '0px',
          [theme.breakpoints.down(664)]: {
            flexDirection: 'column',
            alignItems: 'flex-start',
          },
        }}
      >
        <Box>
          <FlexBox
            sx={{
              marginBottom: '4px',
            }}
          >
            <Typography
              variant="h3"
              lineHeight="24px"
              fontWeight={400}
              letterSpacing="0px"
              color="text.quartenery"
              mr={1}
              sx={{
                width: 140,
              }}
            >
              {getData(user?.address, 4, 4)}
            </Typography>
            <CopyIcon
              stroke={theme.palette.info.light}
              sx={{
                fontSize: '24px',
                cursor: 'pointer',
                color: 'transparent',
              }}
              onClick={copyAddress}
            />
          </FlexBox>
          <FlexBox>
            <StyledLink
              href={`${process.env.REACT_APP_FIRECHAIN_EXPLORER}account/${user?.address}?=!isEVM`}
              target="_blank"
              rel="noopener noreferrer"
            >
              View on 5ire explorer
            </StyledLink>{' '}
            <ArrowRightObliqueUpIcon
              sx={{
                fontSize: '13px',
              }}
            />
          </FlexBox>
        </Box>
        <DisconnectButton variant="contained" onClick={logoutHandler} fullWidth>
          Disconnect
        </DisconnectButton>
      </DialogActions>
    </StyledDialog>
  );
};

export default DisconnectModal;
