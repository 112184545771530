/*eslint-disable*/
import { DialogActions, DialogContent, Typography } from '@mui/material';
import { StyledDialog, OkButton } from './styles';
import { MIN_STAKE } from '../../constant';
import { useLocation } from 'react-router-dom';

const FundsModal = ({ isOpen, handleClose, notConnected, isRestart, handleCloseModal, isStop }) => {
  const location = useLocation();
  return (
    <StyledDialog
      open={isOpen}
      onClose={isRestart ? handleCloseModal : handleClose}
      aria-labelledby="logout-dialog-title"
      aria-describedby="logout-dialog-description"
    >
      <Typography variant="h1" color="text.primary" textAlign="center">
        {notConnected ? 'Error' : isRestart ? 'Message' : 'Notification'}
      </Typography>
      <DialogContent sx={{ padding: '12px 0 24px' }}>
        <Typography variant="h6" color="text.primary" textAlign="center">
          {notConnected
            ? 'The connection attempt failed please try again and follow these steps'
            : isRestart
              ? `The bond amount is less than the minimum required amount i.e ${MIN_STAKE}, kindly bond more funds and try again`
              : (location?.pathname == "/account/funds/start-validating" || location?.pathname == '/account/become-a-validator') ? location?.pathname == '/account/become-a-validator' ?
                'During the shuffling of validators in the validators set, you will be unable to access the bond information section for one ERA.'
                : 'Your account status may take some time to update and reflect on the dashboard \n  During the shuffling of validators in the validators set, you will be unable to access the bond information section for one ERA.'
                : 'Your account status may take some time to update and reflect on the dashboard'}
        </Typography>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'center' }}>
        <OkButton variant="contained" onClick={isRestart ? handleCloseModal : handleClose}>
          Ok
        </OkButton>
      </DialogActions>
    </StyledDialog>
  );
};

export default FundsModal;

