import { combineReducers } from '@reduxjs/toolkit';
import authReducer from './reducer/auth';
import drawerReducer from './reducer/drawer';
import setUpValidatorReducer from './reducer/setUpValidator';
import { validatorApi } from './Api/validatorApi';
import { LoginApi } from './Api/LoginApi';

const combinedReducer = combineReducers({
  auth: authReducer,
  drawer: drawerReducer,
  validatorStatus: setUpValidatorReducer,
  [validatorApi.reducerPath]: validatorApi.reducer,
  [LoginApi.reducerPath]: LoginApi.reducer,
});

const rootReducer = (state, action) => {
  if (action.type === 'drawer/logout') {
    state = undefined;
    const currentTheme = localStorage.getItem('light');
    localStorage.clear();
    localStorage.setItem('light', currentTheme);
  }
  return combinedReducer(state, action);
};

export default rootReducer;
