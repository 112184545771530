export function toFixed(num, fixed) {
  fixed = fixed || 0;
  fixed = Math.pow(10, fixed);
  return Math.floor(num * fixed) / fixed;
}

/*CALCULATION OF ERA TIME */

export const eraTimeCalculation = (givenEra, currentEra) => {
  let remainingEra = givenEra - currentEra;
  let minutesRemaining = remainingEra * 2;
  let millisecondEra = minutesRemaining * 60000;
  let diff = Date.now() - Date.now() + millisecondEra;
  return diff;
};
