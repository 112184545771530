import { Tooltip, useTheme } from '@mui/material';

const ToolTip = ({ children, ...props }) => {
  const theme = useTheme();
  return (
    <Tooltip
      arrow
      {...props}
      componentsProps={{
        tooltip: {
          sx: {
            bgcolor:
              theme.palette.mode === 'dark' ? theme.palette.white.main : theme.palette.gray.light,
            color: 'common.black',
            '& .MuiTooltip-arrow': {
              color:
                theme.palette.mode === 'dark' ? theme.palette.white.main : theme.palette.gray.light,
            },
          },
        },
      }}
    >
      {children}
    </Tooltip>
  );
};
export default ToolTip;
