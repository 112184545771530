/*eslint-disable*/
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { logout } from '../../redux/reducer/auth';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from '../Toast';
import { getApiData } from '../../utils/webClientsContext';
const events = ['load', 'mousemove', 'mousedown', 'click', 'scroll', 'keypress'];

const AppLogout = ({ children }) => {
  const { setIsNodeExist } = getApiData();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let timer;
  const data = useSelector((state) => state);

  const resetTimer = () => {
    if (timer) clearTimeout(timer);
  };

  useEffect(() => {
    timer = setTimeout(() => {
      resetTimer();
      Object.values(events).forEach((item) => {
        window.removeEventListener(item, resetTimer);
      });
      if (data?.auth?.isLoggedIn === true) {
        logoutAction();
      }
    }, 600000);
  }, [data?.auth?.isLoggedIn === true]);

  const logoutAction = () => {
    try {
      window.fire.disconnect().then((res) => {
        if (res) {
          sessionStorage.clear();
          dispatch(logout());
          toast.error('Session Expired');
          setIsNodeExist(false);
          navigate('/login');
          window?.fire?.removeAllListeners();
        }
      });
      // .catch((err)=>{

      // });
    } catch {
      sessionStorage.clear();
      dispatch(logout());
      toast.error('Session Expired');
      setIsNodeExist(false);
      navigate('/login');
      window?.fire?.removeAllListeners();
    }
  };

  return children;
};

export default AppLogout;
