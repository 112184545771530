import { Button, Dialog, styled, TextField } from '@mui/material';

export const StyledDialog = styled(Dialog)(({ theme }) => ({
  zIndex: 20000,
  background: 'rgba(8, 19, 44, 0.6)',
  backdropFilter: 'blur(2px)',
  '& .MuiPaper-root': {
    minWidth: 450,
    maxWidth: '600px',
    padding: '30px 24px',
    background: theme.palette.primary.main,
    border: `1px solid ${theme.palette.blue.main}`,
    backdropFilter: 'blur(10px)',
    borderRadius: '20px',
    [theme.breakpoints.down(500)]: {
      minWidth: 320,
      maxWidth: 450,
    },
  },
}));

export const OkButton = styled(Button)(({ theme }) => ({
  padding: '12px 24px',
  backgroundColor: theme.palette.blue.main,
  border: 'none',
  color: theme.palette.white.main,
  textTransform: 'uppercase',
  borderRadius: '6px',
  '&:hover': {
    backgroundColor: theme.palette.blue.main,
    border: 'none',
  },
}));

export const CancelButton = styled(Button)(({ theme }) => ({
  padding: '12px 24px',
  backgroundColor: theme.palette.red.main,
  border: 'none',
  color: theme.palette.white.main,
  textTransform: 'uppercase',
  borderRadius: '6px',
  '&:hover': {
    backgroundColor: theme.palette.red.main,
    border: 'none',
  },
}));

export const StyledTextFiled = styled(TextField)(({ theme }) => ({
  width: '100%',
  '.MuiFormLabel-root': {
    color: `${theme.palette.text.primary} !important`,
    background: theme.palette.primary.main,
    '&.Mui-focused': {
      color: `${theme.palette.text.primary} !important`,
      background: theme.palette.primary.main,
    },
  },
  '.MuiInputBase-root': {
    borderRadius: '10px',
    border: `1px solid ${theme.palette.info.main} !important`,
    '&:hover': {
      border: `1px solid ${theme.palette.info.main} !important`,
    },
  },
}));
