import { useMediaQuery, useTheme } from '@mui/material';
import { useCallback, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

import Sidebar from '../Sidebar';
import Statistics from '../Statistics';
import { toggleSidebar } from '../../redux/reducer/drawer';
import { Container, Wrapper } from './styles';

import MenuIcon from '../../assets/Icons/MenuIcon';

const MainPanel = ({ children }) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const [isMobileSidebarOpen, setIsMobileSidebarOpen] = useState(false);
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const location = useLocation();

  const { pathname } = location;

  const showStats =
    pathname.includes('account/become-a-validator') ||
    !pathname.includes('/account') ||
    pathname === '/account-setup';
  useEffect(() => {
    dispatch(toggleSidebar(!isMobile));
  }, [dispatch, isMobile]);

  const openSidebar = useCallback(() => {
    setIsMobileSidebarOpen(true);
  }, []);

  return (
    <Wrapper>
      <Sidebar
        isMobileSidebarOpen={isMobileSidebarOpen}
        setIsMobileSidebarOpen={setIsMobileSidebarOpen}
      />
      <Container ismobile={`${isMobile}`}>
        {isMobile && (
          <MenuIcon
            sx={{
              color: theme.palette.text.primary,
              fill: theme.palette.text.primary,
            }}
            onClick={openSidebar}
          />
        )}
        {showStats && <Statistics />}
        {children}
      </Container>
    </Wrapper>
  );
};

export default MainPanel;
