import { SvgIcon, useTheme } from '@mui/material';

const ArrowBackIcon = (props) => {
  const theme = useTheme();
  return (
    <SvgIcon viewBox="0 0 16 17" {...props}>
      <path
        d="M16 7.71472H3.83L9.42 2.12472L8 0.714722L0 8.71472L8 16.7147L9.41 15.3047L3.83 9.71472H16V7.71472Z"
        fill={theme.palette.blue.main}
      />
    </SvgIcon>
  );
};

export default ArrowBackIcon;
