import { NotificationContainer } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import { BrowserRouter } from 'react-router-dom';
import RoutePath from './routes';
import AutoLogout from './components/SessionExpired/AutoLogout';

function App() {
  return (
    <BrowserRouter>
      <RoutePath />
      <AutoLogout />
      <NotificationContainer />
    </BrowserRouter>
  );
}

export default App;
