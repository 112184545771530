import { SvgIcon, useTheme } from '@mui/material';

const ArrowRightObliqueUpIcon = (props) => {
  const theme = useTheme();
  return (
    <SvgIcon viewBox="0 0 13 13" {...props}>
      <path
        d="M2.36399 12.364L10.9695 3.75847L10.9695 11.6639L12.9706 11.6569L12.9706 0.343145L1.65688 0.343144L1.65688 2.33719L9.55526 2.34426L0.949772 10.9497L2.36399 12.364Z"
        fill={theme.palette.info.light}
      />
    </SvgIcon>
  );
};

export default ArrowRightObliqueUpIcon;
