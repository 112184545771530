import { BallTriangle } from 'react-loader-spinner';

const Loader = () => {
  return (
    <div
      style={{
        height: '100vh',
        width: '100%',
        backgroundColor: '#08132cf0',
        zIndex: 10000000000,
        position: 'fixed',
        top: 0,
        left: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: '35px',
      }}
    >
      <BallTriangle color="#00BFFF" height={80} width={80} />
    </div>
  );
};

export default Loader;
